.menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    height: 70px;
    width: 100%;
}

.active-item {
    background: #0D345B;
    border-left: 4px solid;
    border-color: #FD3C9F;
}

.non-active-item {
    border-left: 4px solid;
    border-color: transparent;
}