@font-face {
  font-family: 'LeagueGothic';
  src: local('LeagueGothic-Regular'), url('assets/fonts/LeagueGothic-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'A-Symbol-Underlined';
  font-weight: normal;
  font-style: normal;
  src: url('assets/fonts/a-symbol-underlined.woff') format('woff');
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

body {
  &::-webkit-scrollbar-track {
    margin-top: 64px;
  }
}

::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: #b0b0b0;
}

/*&::-webkit-scrollbar-thumb:hover {*/
/*  background: #808080;*/
/*}*/
